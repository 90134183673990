<template>
  <div style="width: 100%">
    <v-list dense style="width: 100%; background: transparent">
      <v-subheader style="font-size: 14px">Actions</v-subheader>
      <v-divider></v-divider>
      <v-list-item @click="exportCSV">
        <v-list-item-action>
          <v-icon small color="grey">file_download</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Export data to CSV </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="exportExcel">
        <v-list-item-action>
          <v-icon small color="grey">file_download</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Export data to Excel </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </div>
</template>
<script>
export default {
  data: () => ({
    item: null,
  }),
  mounted() {
    this.item = this.params;
  },
  methods: {
  
    exportCSV() {
      this.params.exportCSV();
    },
    exportExcel() {
      this.params.exportExcel();
    },
  },
};
</script>